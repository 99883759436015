import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';

type GlobalFooterProps = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      minHeight: '40px',
      textAlign: 'center',
      lineHeight: '40px',
      display: 'table-cell',
      verticalAlign: 'middle',
      color: 'white',
    },
  }),
);

const GlobalFooter: React.FC<GlobalFooterProps> = React.memo(() => {
  const classes = useStyles();

  return (
    <Grid container>
      <Hidden xsDown>
        <Grid item xs={12}>
          <div className={classes.footer}>
            <a
              href='https://twitter.com/iomiki_bdg'
              target='_blank'
              rel='noreferrer noopener'
            >
              @iomiki_bdg
            </a>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
});
export default GlobalFooter;
