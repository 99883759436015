import React, { FC } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import { pages } from './components/pages';
import reportWebVitals from './reportWebVitals';
import { Provider } from './logics/reducers/GlobalState/Provider';

const App: FC = () => (
  <Provider>
    <BrowserRouter>
      <Switch>
        {pages.map((page, i) => (
          <Route
            exact
            // eslint-disable-next-line react/no-array-index-key
            key={`PAGES_No.${i}`}
            path={page.path}
            component={page.component}
          />
        ))}
      </Switch>
    </BrowserRouter>
  </Provider>
);

render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
reportWebVitals();
