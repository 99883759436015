import React from 'react';
import { RouteComponentProps } from 'react-router';
import PageTemplate from '../../templates/PageTemplate';

// eslint-disable-next-line arrow-body-style
export const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <PageTemplate>アクセスされたURLは存在しません。ばいばい。</PageTemplate>
  );
};
