/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useRef, FC, useContext } from 'react';
import { whoContext, talkContext, iconContext } from '../../pages/Test/use';
// import { iconContext } from '../../pages/Test/use';

type Props = {
  canvasWidth: number; // canvas幅
  canvasHeight: number; // canvas高さ
  x: number; // 矩形始点x
  y: number; // 矩形始点y
  width: number; // 矩形幅
  height: number; // 矩形高さ
};

const Canvas: FC<Props> = React.memo(
  ({ canvasWidth, canvasHeight, x, y, width, height }) => {
    const canvasRef = useRef(null);

    const who = useContext(whoContext);
    const talk = useContext(talkContext);
    const icon = useContext(iconContext);

    const getContext = (): CanvasRenderingContext2D => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const canvas: any = canvasRef.current;

      return canvas.getContext('2d');
    };

    useEffect(() => {
      const ctx: CanvasRenderingContext2D = getContext();

      // 背景画像読み込み
      const back = new Image();
      back.src = `${process.env.PUBLIC_URL}icon/boder.png`; // 画像のURLを指定
      back.onload = () => {
        // ctx.drawImage(back, x, y, width, height);
        const iconImg = new Image();
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        iconImg.src = `${process.env.PUBLIC_URL}${icon}`; // 画像のURLを指定
        iconImg.onload = () => {
          // 背景とアイコン両方のロードが完了してから描画
          ctx.drawImage(back, x, y, width, height);
          ctx.drawImage(iconImg, 20, 25, 110, 110);

          ctx.font = "bold 18px 'ＭＳ 明朝'";
          ctx.fillStyle = 'rgb(114, 68, 33)';
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          ctx.fillText(`${who}のヒミツ`, 150, 50);

          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const message = `${talk}`;
          const messageList = message.split('\n');

          let writeY = 100;
          messageList.forEach((msg) => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            ctx.fillText(msg, 150, writeY);
            writeY += 18;
          });
        };
      };
    }, [x, y, width, height, icon, who, talk]);

    return <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />;
  },
);

export default Canvas;
