import React, { useState } from 'react';
// import { Link } from "react-router-dom";
// import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { ShareDialog } from '../ShareDialog';

type GlobalHeaderProps = React.Props<{}>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.primary.main,
    },
    snsShareButtonArea: {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-around',
    },
    snsShareButton: {},
    copyToCripboardButton: {},
  }),
);

export const GlobalHeader: React.FC<GlobalHeaderProps> = React.memo(() => {
  const classes = useStyles();

  const [isDisplaySareDialog, setIsDisplaySareDialog] = useState(false);

  const displayShareDialog = () => {
    setIsDisplaySareDialog(!isDisplaySareDialog);
  };

  // シェアボタン用
  const shareUrl = 'https://animel.f-arts.work';
  const title = 'Animel | アニメなにみる？';

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.footer}>
        <Toolbar>
          <IconButton edge='start' color='inherit' aria-label='menu'>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            ウマ娘セリフジェネレーター的な何か
          </Typography>

          <TwitterShareButton
            url={shareUrl}
            title={title}
            className={classes.snsShareButton}
          >
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <Button color='inherit' onClick={displayShareDialog}>
            Share
          </Button>

          <ShareDialog
            open={isDisplaySareDialog}
            onClose={displayShareDialog}
            title='サンプルアプリをshareする'
          />
        </Toolbar>
      </AppBar>
    </div>
  );
});
