import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// SNS用シェアボタン
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  TumblrShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TumblrIcon,
  EmailIcon,
} from 'react-share';

// クリップボードにコピー
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    snsShareButtonArea: {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-around',
    },
    snsShareButton: {},
    copyToCripboardButton: {},
  }),
);

type ShareDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
};

export const ShareDialog: React.FC<ShareDialogProps> = React.memo((props) => {
  const classes = useStyles();

  // シェアボタン用
  const shareUrl = 'https://animel.f-arts.work';
  const title = 'Animel | アニメなにみる？';

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={classes.root}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <Divider />
      <DialogContent className={classes.snsShareButtonArea}>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className={classes.snsShareButton}
        >
          <FacebookIcon size={48} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className={classes.snsShareButton}
        >
          <TwitterIcon size={48} round />
        </TwitterShareButton>
      </DialogContent>
      <DialogContent className={classes.snsShareButtonArea}>
        <LinkedinShareButton
          url={shareUrl}
          title={title}
          windowWidth={750}
          windowHeight={600}
          className={classes.snsShareButton}
        >
          <LinkedinIcon size={48} round />
        </LinkedinShareButton>
        <TumblrShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className={classes.snsShareButton}
        >
          <TumblrIcon size={48} round />
        </TumblrShareButton>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body='body'
          className={classes.snsShareButton}
        >
          <EmailIcon size={48} round />
        </EmailShareButton>
      </DialogContent>
      <DialogContent className={classes.snsShareButtonArea}>
        <CopyToClipboard
          text={shareUrl}
          onCopy={
            () => {
              console.log('aaa');
            }
            // actions.setNotification('success', 'クリップボードにコピーしました')
          }
        >
          <Button
            variant='contained'
            color='primary'
            className={classes.copyToCripboardButton}
          >
            URLをコピー
          </Button>
        </CopyToClipboard>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => props.onClose()} color='primary' autoFocus>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
});

// ShareDialog
