import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, Card, Grid, TextField } from '@material-ui/core';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import umaData from '../../../data/umamusume.json';
import PageTemplate from '../../templates/PageTemplate';
import { whoContext, talkContext, iconContext } from './use';
import Canvas from '../../atoms/Canvas';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: 'auto',
      padding: theme.spacing(2),
      maxWidth: '50%',
      maxHeight: '100%',
      // transition: '0.3s',
      // boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      /*       '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      }, */
    },
    button: {
      width: 'auto',
      padding: '0',
      margin: '0',
      background: 'none',
      border: '0',
      fontSize: '0',
      lineHeight: '0',
      overflow: 'visible',
      cursor: 'pointer',
    },
  }),
);

type umaDataType = {
  id: number;
  name: string;
  appropriate: string;
  distance: string[];
  legQuality: string[];
  image: string;
  himitsu: string;
};

// eslint-disable-next-line arrow-body-style
export const Test: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const list: umaDataType[] = umaData.uma;

  const [who, setWho] = useState<string>('');
  const [talk, setTalk] = useState<string>('');
  const [icon, setIcon] = useState<string>('');

  const [charactor, setCharactor] = useState<umaDataType>({
    id: 1,
    name: 'スペシャルウィーク',
    appropriate: '芝',
    distance: ['中距離', '長距離'],
    legQuality: ['先行', '差し'],
    image: 'icon/1.png',
    himitsu: '実は、利き牛乳が出来る。',
  });

  const clickCharactorImg = (id: number) => {
    const targetUma = umaData.uma.filter((element) => element.id === id)[0];
    setCharactor(targetUma);
  };

  // const onChangeTalk = () => {};

  useEffect(() => {
    setIcon(charactor.image);
    setWho(charactor.name);
    setTalk(charactor.himitsu);
  }, [charactor]);

  useEffect(() => {
    console.log(talk);
  }, [talk]);

  useEffect(() => {
    console.log(icon);
  }, [icon]);

  return (
    <PageTemplate>
      <Box m={2} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              {list.map((data) => (
                <button
                  id='imgButton'
                  aria-label='実行すると何かが起こるボタン'
                  type='button'
                  className={classes.button}
                  onClick={() => clickCharactorImg(data.id)}
                  key={data.id}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/${data.image}`}
                    width='55'
                    height='55'
                    alt={data.name}
                    key={data.id}
                    role='presentation'
                  />
                </button>
              ))}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <form noValidate autoComplete='off'>
                <TextField
                  id='outlined-basic'
                  label={`${who}のヒミツ`}
                  variant='outlined'
                  fullWidth
                  onChange={(event) => setTalk(event.target.value)}
                  multiline
                  rows={2}
                  value={talk}
                />
              </form>
            </Card>
          </Grid>
          {/*           <Grid item xs={12}>
            <Card className={classes.card}>
              <img
                src={`${process.env.PUBLIC_URL}/${charactor.image}`}
                width='55'
                height='55'
                alt={charactor.name}
                key={charactor.id}
                role='presentation'
              />

              {talk}
            </Card>
          </Grid> */}
          <Grid item xs={12}>
            <Card className={classes.card}>
              <whoContext.Provider value={who}>
                <talkContext.Provider value={talk}>
                  <iconContext.Provider value={icon}>
                    <Canvas
                      canvasWidth={700}
                      canvasHeight={160}
                      x={5}
                      y={5}
                      width={690}
                      height={150}
                    />
                  </iconContext.Provider>
                </talkContext.Provider>
              </whoContext.Provider>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </PageTemplate>
  );
};
