import React, { FC } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  MuiThemeProvider,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import { GlobalHeader } from '../../organisms/GlobalHeader';
import GlobalFooter from '../../organisms/GlobalFooter';
import myTheme from '../../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
  }),
);

type PageTemplateProps = {
  children: React.ReactNode;
};

const PageTemplate: FC<PageTemplateProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={myTheme}>
      <CssBaseline />

      <GlobalHeader />

      <div className={classes.toolbar} />

      <div>{children}</div>

      <GlobalFooter />
    </MuiThemeProvider>
  );
};

export default PageTemplate;
