/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Test } from './Test';
import { Test2 } from './Test2';
import { NotFound } from './NotFound';

export interface PageList {
  component: React.FC<RouteComponentProps>;
  path: string | string[] | undefined;
  pageTitle: string;
}

export const pages: PageList[] = [
  {
    component: Test,
    path: '/',
    pageTitle: 'Test　その１',
  },
  {
    component: Test2,
    path: '/2',
    pageTitle: 'Test　その２',
  },
];

pages.push({
  component: NotFound,
  path: '*',
  pageTitle: 'Not Foundのぺーじ',
});
