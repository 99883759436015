import { createMuiTheme } from '@material-ui/core/styles';
// import green from '@material-ui/core/colors/green';
import cyan from '@material-ui/core/colors/cyan';

// Material UIのテーマ上書き
export const theme = createMuiTheme({
  // カラーパレット追加
  palette: {
    primary: cyan,
  },
  // レスポンシブのブレイクポイント
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 360, // スマホ用
      sm: 768, // タブレット用
      md: 992, // PC用
      lg: 1400,
      xl: 1800,
    },
  },
  typography: {
    useNextVariants: true, // Migration to typography v2
    htmlFontSize: 10,
    fontSize: 10,
    fontFamily: [
      'NewRodinPro-EB',
      'Verdana',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  // classのstyleを上書き
  overrides: {
    MuiButton: {
      root: {
        // ボタン内アルファベット文字を大文字変換しない
        textTransform: 'none',
      },
    },
  },
  // shadows: Array(25).fill('none'),
  spacing: 8,
});

export default theme;
